import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`8th March 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A user now can generate `}<inlineCode parentName="p">{`cache-control`}</inlineCode>{` header value programmatically. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1615"
          }}>{`#1615`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpHeaders headers = HttpHeaders.of();
HttpFileBuilder fileBuilder = HttpFileBuilder.of(...);
HttpFileServiceBuilder fileServiceBuilder = HttpFileServiceBuilder.forFileSystem(...);

// Before:
headers.set(HttpHeaderNames.CACHE_CONTROL, "no-cache, no-store, must-revalidate"));
fileBuilder.setHeader(HttpHeaderNames.CACHE_CONTROL,
                      "max-age=86400, no-cache, must-revalidate");
fileServiceBuilder.setHeader(HttpHeaderNames.CACHE_CONTROL, "public, max-age=3600");

// After:
headers.setObject(HttpHeaderNames.CACHE_CONTROL, ServerCacheControl.DISABLED);
fileBuilder.cacheControl(new ServerCacheControlBuilder()
                                 .maxAgeSeconds(86400)
                                 .noCache()
                                 .mustRevalidate()
                                 .build());
fileServiceBuilder.cacheControl(new ServerCacheControlBuilder()
                                        .cachePublic()
                                        .maxAgeSeconds(3600)
                                        .build());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria supports `}<a parentName="p" {...{
            "href": "https://github.com/grpc/grpc-java/blob/master/documentation/server-reflection-tutorial.md"
          }}>{`gRPC Server Reflection`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1623"
          }}>{`#1623`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.service(new GrpcServiceBuilder().addService(new MyHelloService())
                                   .addService(ProtoReflectionService.newInstance())
                                   .build());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`StreamMessage.drailAll(...)`}</inlineCode>{` to simply collect all the elements from the `}<inlineCode parentName="p">{`StreamMessage`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1620"
          }}>{`#1620`}</a>{` `}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpResponse res = HttpResponse.of(HttpHeaders.of(HttpStatus.OK)
                                              .contentType(PLAIN_TEXT_UTF_8),
                                   HttpData.ofUtf8("bob"),
                                   HttpHeaders.of(CONTENT_MD5, "hash"));
// Simply collect all the elements.
List<HttpObject> drained = res.drainAll().join();
assertThat(drained).containsExactly(
        HttpHeaders.of(HttpStatus.OK)
                   .contentType(PLAIN_TEXT_UTF_8),
        HttpData.of(StandardCharsets.UTF_8, "bob"),
        HttpHeaders.of(CONTENT_MD5, "hash"));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A user is able to see the HTTP trailers in a `}<inlineCode parentName="p">{`RequestLog`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1614"
          }}>{`#1614`}</a>{` `}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Added more logging to `}<inlineCode parentName="li">{`SamlService`}</inlineCode>{` to inform the reasons of the failure. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1629"
        }}>{`#1629`}</a>{` `}</li>
      <li parentName="ul">{`Various documentation updates. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1604"
        }}>{`#1604`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1607"
        }}>{`#1607`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1610"
        }}>{`#1610`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1611"
        }}>{`#1611`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1613"
        }}>{`#1613`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1618"
        }}>{`#1618`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1626"
        }}>{`#1626`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1627"
        }}>{`#1627`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1628"
        }}>{`#1628`}</a>{`  `}</li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a bug where HTTP trailers is added to message headers. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1620"
        }}>{`#1620`}</a></li>
      <li parentName="ul">{`Fixed a bug where the TLS handshake exception pollutes the log message. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1612"
        }}>{`#1612`}</a>{` `}</li>
      <li parentName="ul">{`Fixed a bug where Server-Sent Events messages miss the `}<inlineCode parentName="li">{`end-of-line`}</inlineCode>{` character. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1610"
        }}>{`#1610`}</a></li>
      <li parentName="ul">{`Fixed an API design flaw where a user cannot specify more than one decorator when adding a `}<inlineCode parentName="li">{`ServiceWithPathMappings`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1627"
        }}>{`#1627`}</a>{` `}</li>
      <li parentName="ul">{`Fixed a bug where `}<inlineCode parentName="li">{`contentPreview`}</inlineCode>{` is not working correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1624"
        }}>{`#1624`}</a>{` `}</li>
      <li parentName="ul">{`Fixed a bug where a `}<inlineCode parentName="li">{`StackOverFlowError`}</inlineCode>{` is raised while finding annotations in `}<inlineCode parentName="li">{`AnnotationUtil`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1635"
        }}>{`#1635`}</a>{` `}</li>
      <li parentName="ul">{`Fixed a bug where the Armeria actuator sends the response with the incorrect media type. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1641"
        }}>{`#1641`}</a>{` `}</li>
    </ul>
    <h2 {...{
      "id": "breaking-change",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-change",
        "aria-label": "breaking change permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking Change`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`HttpHeaders`}</inlineCode>{` is added to `}<inlineCode parentName="li">{`get()`}</inlineCode>{` in `}<inlineCode parentName="li">{`HttpVfs`}</inlineCode>{` as a parameter. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1615"
        }}>{`#1615`}</a>{` `}
        <ul parentName="li">
          <li parentName="ul">{`A user can easily add the `}<inlineCode parentName="li">{`HttpHeaders`}</inlineCode>{` when building an `}<inlineCode parentName="li">{`HttpFile`}</inlineCode>{`. `}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Caffeine 2.6.2 -> 2.7.0`}</li>
      <li parentName="ul">{`Curator 4.1.0 -> 4.2.0`}</li>
      <li parentName="ul">{`gRPC 1.18.0 -> 1.19.0`}</li>
      <li parentName="ul">{`Hibernate Validator 6.0.14 -> 6.0.15`}</li>
      <li parentName="ul">{`Jetty 9.4.14 -> 9.4.15`}</li>
      <li parentName="ul">{`protobuf-java 3.5.1 -> 3.6.1`}</li>
      <li parentName="ul">{`RxJava 2.2.6 -> 2.2.7`}</li>
      <li parentName="ul">{`SLF4J 1.7.25 -> 1.7.26`}</li>
      <li parentName="ul">{`Spring Boot 2.1.2 -> 2.1.3`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      